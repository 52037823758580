import felix_pic from '../img/felix_pic.JPG';
import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Felix',
  lastName: 'Kleine Bösing',
  initials: 'FKB',
  position: 'a Machine Learning Engineer',
  selfPortrait: felix_pic,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: '🇩🇪',
      text: 'based in Münster, Germany',
    },
    {
      emoji: '💻',
      text: 'Senior Machine Learning Engineer at Accenture',
    },
    {
      emoji: '📧',
      text: 'felix.boesing@t-online.de',
    },
  ],
  socials: [
    {
      link: 'CV.pdf',
      icon: 'fa fa-file',
      label: 'resume',
    },
    {
      link: 'https://github.com/FelixKleineBoesing',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/felix-kleine-b%C3%B6sing-63a841138/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
    {
      link: 'https://stackoverflow.com/users/8384047/felix-kleine-b%c3%b6sing',
      icon: 'fa fa-stack-overflow',
      label: 'stackoverflow',
    },
    {
      link: 'https://mentorcruise.com/mentor/FelixKleineBsing/',
      icon: 'fa fa-graduation-cap',
      label: 'mentorcruise',
    },
  ],
  bio: "I am a Machine Learning Engineer who excels in building machine learning products and developing technical teams. With a strong software development and business background, I am focused on creating business value for my clients. I have acquired a broad skillset through many professional and private project",
  skills: {
    Languages: ['Python', 'R', 'SQL', 'Rust', 'C++', 'HTML/CSS', "TypeScript", "JavaScript"],
    frameworks: [
      'PyTorch',
      'TensorFlow',
      'Scikit-Learn',
      "Pandas",
        "Numpy",
        "Scipy",
        "Google OR Tools",
        "FastAPI",
        "Flask",
        "StreamLit"
    ],
    Databases: ['PostgreSQL', 'MongoDB', 'Redis', 'Hive', 'Neo4j'],
    CloudServices: [
        'AWS S3', 'AWS EC2', 'AWS SNS', 'AWS Fargate', "AWS EMR", "AWS Batch",
      "AWS Step Functions", "Azure Functions", "Azure Cognitive Services", "Azure Machine Learning", "GCP Vertex"
    ],
    Tools: [
      'Docker',
      'Kubernetes',
      'Spark',
      "Hadoop",
      'Grafana',
      'Prometheus',
      'Elasticsearch',
      'GitLab (CI/CD)',
      'GitHub (Actions)',
      'Jenkins',
      'Jira',
      'Confluence',
      'Bitbucket',
    ],
  },
  hobbies: [
    {
      label: 'Programming',
      emoji: '💻',
    },
    {
      label: 'Reading',
      emoji: '📕',
    },
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Cooking',
      emoji: '🍳',
    },
    {
      label: 'Powerlifting',
      emoji: '💪',
    },
  ],
  portfolio: [
    {
      title: 'Railway optimization',
      description: "Coming Soon",
      techstack: ["Docker", "Python", "AWS", "GitLab CI", "ArgoCD", "Helm", "Postgres", "FastAPI"],
      live: null, //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: null, // this should be a link to the **repository** of the project, where the code is hosted.
      image: null,
    },
    {
      title: 'Development of a Next Best Action Engine',
      description: "Development of a Next Best Action Engine to support sales managers in the \n" +
          "creation of campaigns and bank advisors in the individual support of customers. \n" +
          "In addition to the primary goal of the NBA engine by combining existing and \n" +
          "new models, processes and tools for the ML lifecycle, testing and deployment \n" +
          "and ML monitoring were also introduced to improve the client's data science \n" +
          "environment in terms of benefits, quality and stability",
      techstack: ["Docker", "Python", "Java", "Hive/Hadoop/Spark", "ML Libraries (tensorflow, pytorch, scikit-learn", "Kafka","Bitbucket", "Jenkins"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Reduction of enegery costs of conveyors',
      description: "Reduction of electricity costs of conveyors through time series prediction and reinforcement learning/operations research: The company's conveyor systems were controlled with a highly ingrained control set that was exclusively based on the current filling level of the basin to be pumped. The project aimed to minimise the electricity costs of the pumps while complying with certain constraints. Since the control of the pumps depends, among other things, on the future inflow quantity, this was predicted by time series analysis for the future time units. In addition, a simulation environment for the filling basin and the influencing parameters was developed on the basis of which an agent could be developed. The agent was benchmarked against two independently developed heuristics and the original rule set.",
      techstack: ["Python", "Redis", "Tensorflow", "Docker", "GitLab CI/CD"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Development of an accreditable challenger for the internal rating model',
      description: "The previous models for determining the default probability of loans were based on linear models and were to be challenged due to their advanced age. In addition to the forecast quality, the focus was on the explainability of the model for the potential recognition of the model by BaFin, for which the explainability for individual observations as well as for the entirety had to be guaranteed. Another challenge was the already very low proportion of positive observations, as defaults tend to be low in the low-interest phase. However, data augmentation prevented the relationships between the independent and dependent variables from being quasi-continuous and thus did not further impair the explanatory power.",
      techstack: ["Python", "XGBoost", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Design and development of a forecasting service',
      description: "Design and development of a forecasting service that was used for the Forecasting Enterprise product and several forecasting projects. The service enabled fast deployment for forecasting projects on on-premise and cloud systems, as well as connections to various data sources from databases, web servers and file interfaces. The service was written in such a way that it can be scaled both on-premise and in the cloud (e.g. through Function as a service). The basis is formed by two higher-level systems (AutoML and MetaML), which selects the best algorithms, hyperparameters and feature engineering processors.",
      techstack: ["Python", "C++", "Azure/AWS", "Docker", "Redis", "MongoDB", "PostgresDB", "GitLab CI/CD", "Jira", "Confluence", "RESTful API", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Reduction of personnel costs and delivery delays',
      description: "The logistics provider had very severe schedule variances as deliveries came \n" +
          "from overseas and the delivery date could be off by several weeks. To solve the \n" +
          "problem, probabilistic models were combined with simulations, supplier \n" +
          "evaluations and time series forecasting. The prediction error was reduced by \n" +
          "~50%, which had a direct impact on personnel costs, as employees could be \n" +
          "better allocated and there was no longer a need for short-term demand for \n" +
          "temporary workers. Furthermore, there were not directly measurable effects \n" +
          "on the turnover, as the demand of the end customer business could be better \n" +
          "covered.\n",
      techstack: ["Python", "C++", "R", "Docker", "Postgres", "MongoDB", "Redis", "RESTful API", "CatBoost", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: "Reduce working capital in the form of inventory and improve production planning by forecasting air and oil filter sales",
      description: "Sales of the company's oil and air filters were very volatile, leading to \n" +
          "inefficiencies in production and storage. Very high safety stock levels had to be \n" +
          "maintained in some cases to allow for a certain delivery reliability. The long \n" +
          "supply chain (distributor, wholesaler, retailer/workshop) also led to a strong \n" +
          "bullwhip effect.\n" +
          "The solution was an ensemble model of autoregressive models and XGBoost. \n" +
          "Due to the long distribution chain and the resulting staggered influence over \n" +
          "time, it was possible to use many more variables collected from various external \n" +
          "sources. Among other things, the identification of major reports on air pollution \n" +
          "and pollen counts was an important indicator for sales peaks.",
      techstack: ["Python", "R", "XGBoost", "WebScraping", "RESTful API", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Reduction of stock transfer costs',
      description: "Reduction of stock transfer costs through reinforcement learning/operations \n" +
          "research:\n" +
          "The logistician had high costs due to stock transfers from satellites to the \n" +
          "central warehouse. The goal was to minimise the cost of determining the \n" +
          "storage location upon receipt of goods. A PoC was conducted to apply \n" +
          "reinforcement learning which was decided against the baseline (meta-heuristics \n" +
          "and local optimisation), which could reduce the costs by about 12%. After a \n" +
          "successful PoC, this was subsequently implemented in production",
      techstack: ["Python", "C++", "Docker", "Redis", "MongoDB", "Tensorflow", "plotly Dash", "RESTful API", "GitLab"],

      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Milkrun Optimization',
      description: "Development of an application (data connection, database, application, \n" +
          "interface to the frontend) for the cost-minimal definition of fixed delivery days \n" +
          "for the customer base of a wholesaler (milkrun).\n" +
          "The problem was solved using Google OR tools for the vehicle routing problem \n" +
          "and a tabu search for the combinatorial problem (distribution of delivery days).\n",
      techstack: ["Python", "C++", "Docker", "MongoDB", "Angular", "RESTful API", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Reduction of enegery costs of conveyors',
      description: "Development of a tool to support bank advisors in supporting their clients.\n" +
          "The bank was faced with the problem that it had to cut jobs due to the growing \n" +
          "competitive pressure and the support ratio from advisor to customer was \n" +
          "becoming lower and lower, and thus potentials were also being lost because \n" +
          "the advisor could not deal with his customers in depth.\n" +
          "The solution was a dashboard, which primarily contains customer segmentation \n" +
          "and product recommendations, now relieves the advisor to the extent that he \n" +
          "only has to deal with the longtail of his customers to a small extent and can \n" +
          "instead make offers based on the pre-calculated results.\n" +
          "Factorisation machines, XGBoost and density- and partitioning-based clustering \n" +
          "were used as models.",
      techstack: ["R", "Shiny", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
    {
      title: 'Identify affine customers for a marketing campaign',
      description: "Identify customers with an affinity for closing a deal for an advertising campaign \n" +
          "on a specific financial product:\n" +
          "The company had previously selected their target group using less manual rules. \n" +
          "This project served to chal lenge the previous system.\n" +
          "The goal was to identify a target group of 60k people with the highest \n" +
          "probability of signing up for this specific product. A history of past mail \n" +
          "campaigns was available, but it was not noted whether anyone had purchased \n" +
          "the particular product as a result of a campaign. \n" +
          "The assumption was made that all customers who completed within a period of \n" +
          "n days after the campaign were positive observers. Despite this biasing \n" +
          "assumption, the increase in the acquisition rate after A/B testing was almost \n" +
          "310%.\n After comparing different algorithms, XGBoost with Mean-Encoding of \n" +
          "categorical variables turned out to be the best combination.",
      techstack: ["R", "Shiny", "GitLab"],
      live: null,
      source: null,
      image: null,
    }, {
      title: 'Development of a dashboard to support e-commerce managers in campaign \n' +
          'planning',
      description: "The company had a problem in managing their marketing campaigns. There was \n" +
          "a lack of quantitative analyses that showed the levers the managers could turn \n" +
          "to achieve the desired push of a brand or a product branch. \n" +
          "The solution was a dashboard that combined various analytics to determine a \n" +
          "target audience, product segment and discount. These analyses were:\n" +
          "- Calculation of the resale probability of the customer base.\n" +
          "- Quantify the sales effect in terms of the type of product discounted/promoted \n" +
          "and the amount of the discount.\n" +
          "- Consideration of the strength of influence when advertising through \n" +
          "twitter/instagram/facebook pages.\n" +
          "In addition to a 5.8% increase in sales, this campaign control also led to a higher \n" +
          "contribution margin, as discount codes were no longer issued to customers who \n" +
          "would have bought anyway.",
      techstack: ["R", "Shiny", "GitLab"],
      live: null,
      source: null,
      image: null,
    },
  ],
  "privatePortfolio": [],
};
