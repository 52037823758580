import React from 'react';
import IconLink from './IconLink';
import {Box, Button} from '@mui/material';

class PortfolioBlock extends React.Component {
    state = {
        showMessage: false
    }
    onButtonClickHandler = () => {
        this.setState({showMessage: !this.state.showMessage})
    };
    render() {
  const { image, live, source, title, description } = this.props;
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box
        className={'portfolio'}
        display={'flex'}
        flexDirection={'column'}
        gap={'0.5rem'}
        alignItems={'center'}
        fontSize={'1.5rem'}
        py={'2rem'}
      >
          <h2 style={{ fontSize: '1.0rem', width: '30vw'}}>{title}</h2>
        {image && <Box
            component={'img'}
            src={image}
            alt={'mockup'}
            width={'30vw'}
            marginBottom={'1rem'}
            style={{
                objectFit: 'cover',
                borderRadius: '25px',
                boxShadow:
                    '  0 2.8px 2.2px rgba(0, 0, 0, 0.034),\n' +
                    '  0 6.7px 5.3px rgba(0, 0, 0, 0.048),\n' +
                    '  0 12.5px 10px rgba(0, 0, 0, 0.06),\n' +
                    '  0 22.3px 17.9px rgba(0, 0, 0, 0.072),\n' +
                    '  0 41.8px 33.4px rgba(0, 0, 0, 0.086),\n' +
                    '  0 100px 80px rgba(0, 0, 0, 0.12)',
            }}
        />}
        {live && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={live} title={'Live Demo'} icon={'fa fa-safari'} />
          </Box>
        )}

        {source && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={source} title={'Source Code'} icon={'fa fa-code'} />
          </Box>
        )}
          <Button variant="contained" onClick={this.onButtonClickHandler}>Show Description</Button>
        <Box
            component={'span'}
            fontSize={'1rem'}
            marginLeft={'1rem'}
        >{this.state.showMessage && description}</Box>
      </Box>

    </Box>
  );
}
}

export default PortfolioBlock;
